<template>
  <div>
    <v-card
      class="mb-5 overflow-hidden"
      :loading="loading"
    >
      <v-skeleton-loader
        v-if="loading"
        type="article, actions, article, actions"
      ></v-skeleton-loader>
      <v-form
        v-else
        ref="form"
        v-model="valid"
      >
        <v-card-title>
          Nouvelle facture
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-autocomplete
            v-model="selectedStudent"
            :rules=" [v=> !!v || 'Sélectionner un apprenant']"
            :clearable="true"
            :items="notBilledStudents"
            :filter="filterStudents"
            no-data-text="Pas de stagiaire correspondant"
            outlined
            dense
            label="Stagiaire"
          >
            <template
              slot="item"
              slot-scope="data"
            >
              {{ data.item.prenom }} {{ data.item.nom }} - {{ data.item.formation ? data.item.formation.nom :
                ''
              }}
            </template>
            <template
              slot="selection"
              slot-scope="data"
            >
              {{ data.item.prenom }} {{ data.item.nom }} - {{ data.item.formation ? data.item.formation.nom :
                ''
              }}
            </template>
          </v-autocomplete>
        </v-card-text>
        <v-card-text v-if="isSelected">
          <v-row>
            <v-col>
              <v-text-field
                :value="selectedStudent ? `${selectedStudent.nom} ${selectedStudent.prenom}` : 'Pas d\'apprenant sélectionné'"
                readonly
                dense
              >
              </v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-if="selectedStudent.formation"
                :value="selectedStudent ? `${selectedStudent.formation.nom}` : 'Pas d\'apprenant sélectionné'"
                readonly
                dense
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-text>
          <v-row>
            <v-col>
              <v-text-field
                v-model="number"
                :hint="assignedNumber.toString()"
                :error="number > assignedNumber || number < assignedNumber"
                :error-messages="invoiceNumberErrMsg"
                type="number"
                label="Numéro de facture"
              >
              </v-text-field>
            </v-col>
            <v-col>
              <v-row>
                <v-col>
                  <v-text-field
                    v-if="selectedStudent"
                    v-model="selectedStudent.dureeFormation"
                    type="number"
                    suffix="Heures"
                    :rules="[v => v!==0 || 'Nombre d\'heures requis']"
                    label="Durée de la formation"
                  >
                  </v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    v-if="selectedStudent"
                    v-model="dureeFormationMinutes"
                    type="number"
                    suffix="Minutes"
                    persistent-hint
                    :hint="calculateDuree"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col>
              <v-text-field
                v-if="selectedStudent"
                v-model="selectedStudent.price"
                :rules="[v => v!==0 || 'Prix requis']"
                suffix="€"
                label="Total"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text>
          <v-row>
            <v-col>
              <v-menu
                v-model="menuDateFacturation"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="niceDateFacturation"
                    :append-icon="icons.mdiCalendar"
                    readonly
                    label="Date de facturation"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="dateFacturation"
                  locale="fr"
                  @change="updateDateFacturation"
                  @input="menuDateFacturation = false"
                >
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col>
              <v-menu
                v-model="menuDateEcheance"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="niceDateEcheance"
                    label="Date d'échéance"
                    :append-icon="icons.mdiCalendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="dateEcheance"
                  locale="fr"
                  @change="updateDateEcheance"
                  @input="menuDateEcheance = false"
                >
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              md="6"
            >
              <v-textarea
                v-model="invoicingData"
                row="8"
                label="Intitulé facturation"
              ></v-textarea>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-textarea
                v-model="invoiceTo"
                row="8"
                label="Facturé à "
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-end">
          <v-btn
            color="primary"
            @click="validate"
          >
            Créer
          </v-btn>
        </v-card-actions>
      </v-form>
      <v-alert
        v-model="alert.displayed"
        class="mt-5"
        tile
        dismissible
        prominent
        type="success"
      >
        <v-row align="center">
          <v-col class="grow">
            Facture n° {{ alert.number }} crée avec succès.
          </v-col>
          <v-col class="shrink">
            <v-btn
              color="primary"
              outlined
              @click="goToInvoice"
            >
              Voir
            </v-btn>
          </v-col>
          <v-col class="shrink">
            <v-btn
              color="primary"
              outlined
              @click="goToFile"
            >
              Afficher le PDF
            </v-btn>
          </v-col>
        </v-row>
      </v-alert>
    </v-card>
    <v-card v-if="!loading">
      <v-card-title>
        Information de facturation
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-row>
          <v-col
            cols="12"
            md="3"
          >
            <label for="name">Nom de l'entreprise</label>
          </v-col>

          <v-col
            cols="12"
            md="9"
          >
            <v-text-field
              id="name"
              v-model="options.company.name"
              outlined
              readonly
              dense
              placeholder="Nom de l'entreprise"
              hide-details
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="3"
          >
            <label for="address1">Adresse ligne 1</label>
          </v-col>

          <v-col
            cols="12"
            md="9"
          >
            <v-text-field
              id="address1"
              v-model="options.company.address1"
              outlined
              readonly
              dense
              placeholder="Adresse ligne 1"
              hide-details
            >
            </v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="3"
          >
            <label for="address2">Adresse ligne 2</label>
          </v-col>

          <v-col
            cols="12"
            md="9"
          >
            <v-text-field
              id="address2"
              v-model="options.company.address2"
              outlined
              readonly
              dense
              placeholder="Adresse ligne 2"
              hide-details
            >
            </v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="3"
          >
            <label for="city">Ville</label>
          </v-col>

          <v-col
            cols="12"
            md="9"
          >
            <v-text-field
              id="city"
              v-model="options.company.city"
              md="9"
              outlined
              readonly
              dense
              placeholder="Ville"
              hide-details
            >
            </v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="3"
          >
            <label for="postcode">Code Postal</label>
          </v-col>

          <v-col
            cols="12"
            md="9"
          >
            <v-text-field
              id="postcode"
              v-model="options.company.postcode"
              outlined
              readonly
              dense
              placeholder="Code Postal"
              hide-details
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="3"
          >
            <label for="siren">Numéro SIREN</label>
          </v-col>

          <v-col
            cols="12"
            md="9"
          >
            <v-text-field
              id="siren"
              v-model="options.company.siren"
              outlined
              readonly
              dense
              placeholder="Numéro SIREN"
              hide-details
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="3"
          >
            <label for="siret">Numéro SIRET</label>
          </v-col>

          <v-col
            cols="12"
            md="9"
          >
            <v-text-field
              id="siret"
              v-model="options.company.siret"
              outlined
              readonly
              dense
              placeholder="Numéro SIRET"
              hide-details
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="3"
          >
            <label for="declarationActivite">Numéro de déclaration d'activité</label>
          </v-col>

          <v-col
            cols="12"
            md="9"
          >
            <v-text-field
              id="declarationActivite"
              v-model="options.company.declarationActivite"
              outlined
              readonly
              dense
              placeholder="Numéro de déclaration d'activité"
              hide-details
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="3"
          >
            <label for="email">E-mail</label>
          </v-col>

          <v-col
            cols="12"
            md="9"
          >
            <v-text-field
              id="email"
              v-model="options.company.email"
              outlined
              readonly
              dense
              placeholder="Adresse e-mail"
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="d-flex justify-end mt-5">
        <v-btn
          outlined
          color="primary"
          @click="goToSettings"
        >
          Modifier
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiAccountOutline, mdiCalendar, mdiCellphone, mdiLockOutline, mdiHandOkay } from '@mdi/js'

export default {
  data() {
    return {
      alert: {
        displayed: false,
        number: 0,
        id: 0,
        url: '',
      },
      loading: true,
      valid: true,
      students: [],
      invoices: [],
      dureeFormationMinutes: 0,
      options: [],
      selectedStudent: {
        price: 0,
        dureeFormation: 0,
      },
      number: 0,
      hours: 0,
      invoicingData: '',
      invoiceTo: 'Caisse des Dépôts et des Consignations \n59 Rue de Lille \n75007 Paris',
      menuDateFacturation: false,
      dateFacturation: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      niceDateFacturation: this.parseDate(new Date()),
      menuDateEcheance: false,
      dateEcheance: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      niceDateEcheance: this.parseDate(new Date()),
      icons: {
        mdiAccountOutline,
        mdiCalendar,
        mdiCellphone,
        mdiLockOutline,
        mdiHandOkay,
      },
    }
  },
  computed: {
    calculateDuree() {
      return `Soit ${this.selectedStudent.dureeFormation} heures et ${this.dureeFormationMinutes} minutes`
    },
    assignedNumber() {
      let number = 0
      const lastInvoiceNumber = Math.max(...this.invoices.map(x => x.number))
      number = Number(lastInvoiceNumber) + 1
      if (number !== 0) {
        return number
      }

      return 0
    },
    invoiceNumberErrMsg() {
      if (this.number > this.assignedNumber) {
        return `Pas de facture ${this.assignedNumber}`
      } if (this.number < this.assignedNumber) {
        const matchingInvoice = this.invoices.find(x => Number(x.number) === Number(this.number))
        if (matchingInvoice.student) {
          return `La facture ${this.number} éxiste déjà (${matchingInvoice.student.nom} ${matchingInvoice.student.prenom})`
        }

        return `La facture ${this.number} éxiste déjà`
      }

      return ''
    },
    notBilledStudents() {
      const students = this.students.filter(x => (!x.facture))

      return students
    },
    isSelected() {
      const isNotDefault = Object.keys(this.selectedStudent).length === 2
      const isCleared = this.selectedStudent === null

      return (isNotDefault && isCleared)
    },
  },
  watch: {
    selectedStudent(val) {
      if (val) {
        this.invoicingData = `Dossier n°${val.num_dossier} \nFormation : ${val.formation.nom}\nLicence + Certification\nSession du ${val.debutFormation} au ${val.finFormation}\nStagiaire : ${val.nom} ${val.prenom}\nTaux de réalisation : 100 %`
        console.log(val)
      }
    },
  },
  mounted() {
    this.fetchData()
    this.updateDateFacturation()
  },
  methods: {
    fetchData() {
      this.loading = true
      Promise.all([this.fetchInvoices(), this.fetchStudents(), this.fetchSettings()])
        .then(values => {
          [this.invoices, this.students, this.options] = values
          this.number = this.assignedNumber
        })
        .catch(err => console.log(err))
        .finally(() => {
          this.loading = false
        })
    },
    async fetchInvoices() {
      const response = await this.$http.get(`${process.env.VUE_APP_API_URL}/factures?_sort=number:DESC`)

      return response.data
    },
    async fetchStudents() {
      const response = await this.$http.get(`${process.env.VUE_APP_API_URL}/students`)

      return response.data
    },
    async fetchSettings() {
      const response = await this.$http.get(`${process.env.VUE_APP_API_URL}/options`)

      return response.data
    },
    updateDateFacturation() {
      this.niceDateFacturation = this.parseDate(this.dateFacturation)
      this.dateEcheance = new Date(new Date().setDate(new Date(this.dateFacturation).getDate() + 30)).toISOString().substr(0, 10)
      this.updateDateEcheance(this.dateEcheance)
    },
    updateDateEcheance() {
      this.niceDateEcheance = this.parseDate(this.dateEcheance)
    },
    parseDate(date) {
      return this.$date(new Date(date), 'dd MMMM yyy')
    },
    filterStudents(item, queryText) {
      return (
        item.prenom.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase())
          > -1
        || item.nom.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
        || item.email.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
        || item.formation.nom.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1

      )
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.loading = true

        const durationInMinutes = (parseInt(this.selectedStudent.dureeFormation, 10) * 60) + parseInt(this.dureeFormationMinutes, 10)

        // FORMAT DATA FOR PDF
        const invoiceData = {
          dateFacturation: this.dateFacturation,
          dateEcheance: this.dateEcheance,
          duration: durationInMinutes,
          number: this.number,
          status: 'facture',
          student: this.selectedStudent,
          debutFormation: this.$date(new Date(this.selectedStudent.debutFormation), 'dd/MM/yyyy'),
          finFormation: this.$date(new Date(this.selectedStudent.finFormation), 'dd/MM/yyyy'),
          invoicingData: this.invoicingData,
          invoiceTo: this.invoiceTo,
        }

        // POST TO PDF GENERATOR
        this.$http.post(`${process.env.VUE_APP_API_URL}/factures/pdf/new`, invoiceData)
          .then(res => {
            this.alert = {
              displayed: true,
              number: res.data.number,
              id: res.data.id,
              url: res.data.pdf.url,
              studentPrenom: this.selectedStudent.prenom,
              studentNom: this.selectedStudent.nom,
            }
            this.selectedStudent = {
              price: 0,
              dureeFormation: 0,
            }
            this.dureeFormationMinutes = 0
            this.invoices.push(res.data)
            this.number = this.assignedNumber
            window.open(`${process.env.VUE_APP_API_URL}${this.alert.url}`)
          })
          .catch(error => {
            console.log(error)
            if (error.response.status === 400) {
              this.$store.commit('snackMe', {
                color: 'error',
                text: 'Erreur sur le numéro de facture, rechargez la page.',
                value: true,
              })
            }
          })
          .finally(this.loading = false)
      }
    },
    goToInvoice() {
      this.$router.push({ name: 'invoice-single', params: { id: this.alert.id } })
    },
    goToFile() {
      window.open(`${process.env.VUE_APP_API_URL}${this.alert.url}`)
    },
    goToSettings() {
      this.$router.push({ name: 'settings' })
    },
  },
}
</script>
